import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import authenticityToken from 'packs/common/authenticityToken';
import TransitionWrapper from 'packs/common/TransitionWrapper';
import WistiaVideo from 'packs/common/WistiaVideo';
import Quiz from './Quiz';
import videoState from './VideoState';
import CourseContent from './CourseContent';

import './Chapter.scss';

const IconSwal = withReactContent(Swal);

export default class ChapterVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoWatched: props.todos[0].watched || videoState.isVideoWatched(props.todos[0].id),
      response: props.response,
    };
  }

  componentDidMount() {
    this.loadWistiaVideo();
  }

  loadWistiaVideo = () => {
    window._wq = window._wq || []; // eslint-disable-line no-underscore-dangle
    window._wq.push({ // eslint-disable-line no-underscore-dangle
      id: this.props.video,
      options: {
        playbar: this.props.video_scrubbing_enabled,
      },
      onReady: (video) => {
        video.bind('end', this.completeVideo.bind(this));
      },
    });
  };

  setVideoWatched = () => {
    if (!this.props.todos[0].watched) {
      axios.put(`/api/v2/challenges/${this.props.challenge_id}/todos/${this.props.todos[0].id}/watched`);
    }

    this.setState({ videoWatched: true });
  };

  removeVideoWatched = () => {
    this.setState({ videoWatched: false });
  };

  setRecording = () => axios({
    method: 'post',
    url: `/todos/${this.props.todos[0].id}/simple_recording.js`,
    data: {
      authenticity_token: authenticityToken(),
    },
  });

  showPopUp = () => {
    IconSwal.fire({
      icon: 'success',
      title: 'Chapter completed!',
      confirmButtonText: 'Continue',
      didClose: () => { window.location = `${this.props.redirect}?resume=true`; },
    });
  };

  simpleRecord = () => {
    if (this.props.todos[0].completed_at === null) {
      this.setRecording().then(() => {
        this.showPopUp();
        this.props.onChapterComplete();
      });
    } else {
      this.showPopUp();
    }
  };

  completeVideo = () => {
    if (this.props.quiz === null) {
      this.simpleRecord();
    } else {
      this.setVideoWatched();
    }
  };

  onComplete = (response) => {
    this.setState({ response }, this.props.onChapterComplete);
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h1>{this.props.name}</h1>
        </div>

        <section className="row my-4">
          <div className="col-md-12">
            {this.state.videoWatched && this.props.quiz ? (
              <TransitionWrapper>
                <div>
                  <div className="d-flex flex-direction-column justify-content-center">
                    <button type="button" id="rewatchVideo" onClick={this.removeVideoWatched} className="btn btn-link">Rewatch Video <i className="fa fa-play" /></button>
                  </div>
                  <Quiz
                    {...this.props.quiz}
                    response={this.state.response}
                    redirect={this.props.redirect}
                    onComplete={this.onComplete}
                  />
                </div>
              </TransitionWrapper>
            ) : (
              <div>
                <WistiaVideo video={this.props.video} />
                <CourseContent contents={this.props.contents} />
                <div className="chapter__description my-4 text-center" dangerouslySetInnerHTML={{ __html: this.props.description }} />
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

ChapterVideo.propTypes = {
  challenge_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  quiz: PropTypes.object,
  response: PropTypes.object,
  contents: PropTypes.array,
  video_scrubbing_enabled: PropTypes.bool,
  todos: PropTypes.array,
  redirect: PropTypes.string,
  onChapterComplete: PropTypes.func.isRequired,
};

ChapterVideo.defaultProps = {
  response: null,
  contents: null,
  video_scrubbing_enabled: false,
  todos: null,
  quiz: null,
  redirect: null,
};
