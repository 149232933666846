import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import CourseFooter from './CourseFooter';
import CourseContent from './CourseContent';

export default class CourseLayout extends Component {
  buttonText = () => {
    if (this.props.current_chapter) {
      return this.props.current_chapter.name;
    }
    if (this.props.start_date && moment().isBefore(moment(this.props.start_date).startOf('day'))) {
      return `Begins ${moment(this.props.start_date).fromNow()}`;
    }
    if (!this.props.link && !this.props.rejoinable) {
      return 'Course Closed';
    }
    if (this.props.link && this.props.rejoinable && !this.props.current_chapter) {
      return 'Join Again';
    }
    if (this.props.link) {
      return 'Start Course';
    }
    return '';
  };

  backgroundImage = () => {
    if (this.props.image) {
      return `url( ${this.props.image})`;
    }
    return '';
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h1>{this.props.name}</h1>
        </div>

        <div className={`course-show ${this.props.category}`}>
          <div className="course-header" style={{ backgroundImage: this.backgroundImage() }}>
            <a
              href={this.props.link}
              className={classNames('btn btn-primary btn-join btn-lg shadow-sm', { disabled: !this.props.link })}
            >
              {this.buttonText()} {this.props.link && <i className="fa fa-angle-right" />}
            </a>
          </div>
        </div>

        <CourseContent contents={this.props.contents} />

        <div className="row mt-5">
          <div className="col-md-8 offset-md-2">
            <h2>What you&apos;ll learn</h2>
            <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
          </div>
        </div>

        <CourseFooter chapters={this.props.chapters} />
      </div>
    );
  }
}

CourseLayout.propTypes = {
  name: PropTypes.string.isRequired,
  contents: PropTypes.array.isRequired,
  chapters: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  rejoinable: PropTypes.bool,
  category: PropTypes.string.isRequired,
  image: PropTypes.string,
  current_chapter: PropTypes.object,
  start_date: PropTypes.string,
};

CourseLayout.defaultProps = {
  image: '',
  link: null,
  rejoinable: null,
  current_chapter: null,
  start_date: null,
};
