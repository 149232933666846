/* eslint-disable react/state-in-constructor */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './Modal.scss';

export default class Modal extends React.Component {
  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <Fragment>
        { React.cloneElement(this.props.trigger, { onClick: this.onOpenModal }) }
        <ReactModal
          open={open}
          onClose={this.onCloseModal}
          closeOnEsc
          closeOnOverlayClick
          showCloseIcon={false}
          animationDuration={500}
          blockScroll
          center={false}
          classNames={{ overlay: 'react-modal', modal: 'react-modal__content' }}
          styles={{
            modal: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              maxWidth: '700px',
              width: '100%',
              padding: '15px',
            },
          }}
        >
          <Fragment>
            <button type="button" onClick={this.onCloseModal} className="react-modal__close" aria-label="close" />
            {this.props.children}
          </Fragment>
        </ReactModal>
      </Fragment>
    );
  }
}

Modal.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
