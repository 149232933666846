import React from 'react';
import PropTypes from 'prop-types';

import Question from 'src/forms/Question';
import { convertAnswersToState } from 'src/forms/utils';

export default class QuizResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = { answers: convertAnswersToState(props.answers) };
  }

  render() {
    return (
      <div>
        <div className="margin-top margin-bottom">
          <span dangerouslySetInnerHTML={{ __html: this.props.form.description }} />
        </div>

        {this.props.answers.map(answer => (
          <fieldset className="disabled" key={answer.question.id}>
            <Question
              {...answer.question}
              type={answer.question.kind}
              name={`question_${answer.question.id}`}
              value={this.state.answers[answer.question.id]}
              disabled
              onChange={() => false}
              quiz={this.props.quiz}
            />
          </fieldset>
        ))}
      </div>
    );
  }
}

QuizResponse.propTypes = {
  form: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  quiz: PropTypes.bool.isRequired,
};
