import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from 'src/forms/Form';
import FormResponse from 'src/forms/FormResponse';
import QuizResults from './QuizResults';

export default class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: props.response,
      showResultsPopUp: false,
    };
  }

  onComplete = (response) => {
    this.setState({
      response: response.data,
      showResultsPopUp: true,
    }, () => this.props.onComplete(response.data));
  };

  onRetry = () => {
    this.setState({ response: null });
  };

  onNextButtonClick = () => {
    window.location = this.props.redirect;
  };

  renderResponse = () => {
    if (this.state.response.quiz) {
      return (
        <QuizResults
          {...this.state.response}
          showResultsPopUp={this.state.showResultsPopUp}
          onRetry={this.onRetry}
          onNextButtonClick={this.onNextButtonClick}
        />
      );
    }

    return (
      <FormResponse
        form={this.state.response.form}
        answers={this.state.response.answers}
        scores={this.state.response.scores || []}
      />
    );
  }

  render() {
    return (
      <div>
        {this.state.response ? (
          this.renderResponse()
        ) : (
          <div>
            <Form
              description={this.props.description}
              id={this.props.id}
              redirect={this.props.redirect}
              sections={this.props.sections}
              timestamp={this.props.timestamp}
              title={this.props.title}
              url={this.props.url}
              onComplete={this.onComplete}
              quiz={this.props.quiz}
              hideFlash
            />
          </div>
        )}
      </div>
    );
  }
}

Quiz.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  quiz: PropTypes.bool.isRequired,
  description: PropTypes.string,
  sections: PropTypes.array.isRequired,
  timestamp: PropTypes.string,
  redirect: PropTypes.string,
  response: PropTypes.object,
  onComplete: PropTypes.func,
};

Quiz.defaultProps = {
  description: undefined,
  timestamp: undefined,
  redirect: undefined,
  response: undefined,
  onComplete: undefined,
};
