export class VideoState {
  static KEY = 'course_video_state_v2';

  constructor(storage = window.localStorage) {
    this.storage = storage;
  }

  isVideoWatched = todoId => this.currentVideosWatched().includes(todoId)

  setVideoWatched = (todoId) => {
    const todoIds = new Set(this.currentVideosWatched());
    todoIds.add(todoId);
    this.storage.setItem(VideoState.KEY, JSON.stringify(Array.from(todoIds)));
  }

  currentVideosWatched = () => {
    const todoIds = this.storage.getItem(VideoState.KEY);
    if (todoIds) {
      return JSON.parse(todoIds);
    }
    return [];
  }
}

const videoState = new VideoState();

export default videoState;
