import React from 'react';
import PropTypes from 'prop-types';

const challengeImage = (props) => {
  if (props.image) {
    return <img src={props.image} className="media-item img-fluid" alt="" />;
  }
  return (
    <div className="challenge-icon media-icon">
      <div className="challenge-item-icon">
        <svg className="icon">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#po8-${props.category}`} />
        </svg>
      </div>
      <div className="challenge-item-label">{props.category}</div>
    </div>
  );
};

challengeImage.propTypes = {
  image: PropTypes.any.isRequired,
  category: PropTypes.any.isRequired,
};

const ChallengeDetails = props => (
  <section className="primary-challenge">
    <div className={`lh-card lh-card-featured ${props.category}`}>
      <div className="lh-card-content">
        <div className="content-image" style={{ backgroundImage: `url(${props.image})` }}>
          {challengeImage(props)}
        </div>
        <div className="content-description">
          <small className="text-muted">{props.type}</small>
          <h3 className="content-title">
            {props.name}
          </h3>
          <div className="featured-desc js-collapsed">
            <div className="featured-desc__text" dangerouslySetInnerHTML={{ __html: props.description }} />
          </div>
        </div>
      </div>
      <div className="lh-card-footer">
        <div dangerouslySetInnerHTML={{ __html: props.link }} />
      </div>
    </div>
  </section>
);

export default ChallengeDetails;

ChallengeDetails.propTypes = {
  image: PropTypes.string,
  category: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.node.isRequired,
};

ChallengeDetails.defaultProps = {
  image: null,
  category: 'other',
  name: '',
  description: null,
  type: '',
};
