/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export default class TransitionWrapper extends Component {
  state = {
    entered: false,
  }

  componentDidMount() {
    this.setState({
      entered: true,
    });
  }

  render() {
    return (
      <CSSTransition
        in={this.state.entered}
        timeout={1000}
        classNames="transition"
        unmountOnExit
      >
        {this.props.children}
      </CSSTransition>
    );
  }
}

TransitionWrapper.propTypes = {
  children: PropTypes.object.isRequired,
};
