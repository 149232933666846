import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ChapterTimeline extends Component {
  static renderIcons(chapter) {
    if (chapter.complete) {
      return <i className="fa fa-check" />;
    }
    if (chapter.unlocked && !(chapter.future || chapter.past)) {
      return <i className="fa fa-bookmark" />;
    }
    return <i className="fa fa-lock" />;
  }

  static timelineStatusClass(chapter) {
    if (chapter.complete) {
      return 'timeline__marker--completed';
    }
    if (!chapter.unlocked || chapter.future || chapter.past) {
      return 'timeline__marker--locked';
    }
    return '';
  }

  render() {
    return (
      <div className="timeline">
        <div className="timeline__wrapper">
          {this.props.chapters.map(chapter => (
            <div
              key={chapter.id}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={chapter.long_name}
              className={`timeline__marker ${ChapterTimeline.timelineStatusClass(chapter)} `}
            >
              {ChapterTimeline.renderIcons(chapter)}
            </div>
          ))}

          <div className="timeline__line" />
        </div>
      </div>
    );
  }
}

ChapterTimeline.propTypes = {
  chapters: PropTypes.array.isRequired,
};
