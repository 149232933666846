import React from 'react';
import PropTypes from 'prop-types';

import FormResponseSection from './FormResponseSection';
import { convertAnswersToState } from './utils';

export default class FormResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = { answers: convertAnswersToState(props.answers) };
  }

  render() {
    return (
      <div>
        {this.props.form.description && (
          <div className="margin-top margin-bottom">
            <span dangerouslySetInnerHTML={{ __html: `<span>${this.props.form.description}</span>` }} />
          </div>
        )}

        {this.props.comment && (
          <div className="margin-top margin-bottom">
            <h6 className="text-danger">Admin Comment: </h6>
            <span dangerouslySetInnerHTML={{ __html: `<span>${this.props.comment}</span>` }} />
          </div>
        )}

        {this.props.form.sections.filter(section => section.questions.length > 0).map(section => (
          <FormResponseSection
            {...section}
            key={section.id}
            active={this.props.form.sections.length === 1}
            answers={this.state.answers}
            score={this.props.scores.find(score => score.section_id === section.id)}
          />
        ))}
      </div>
    );
  }
}

FormResponse.propTypes = {
  form: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  scores: PropTypes.array.isRequired,
  comment: PropTypes.string,
};

FormResponse.defaultProps = {
  comment: null,
};
