import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import './ChapterCard.scss';

export default class ChapterCard extends Component {
  headerMarkup = (complete, unlocked, future, past) => {
    if ((complete || unlocked) && !(future || past)) {
      return (
        <a href={`/courses/${this.props.challenge_id}/chapters/${this.props.id}`}>
          <img
            src={this.props.thumbnail}
            alt={this.props.name}
            className="card-img-top"
          />
        </a>
      );
    }
    return (
      <img
        src={this.props.thumbnail}
        alt={this.props.name}
        className="card-img-top"
      />
    );
  };

  // eslint-disable-next-line camelcase
  footerMarkup = (complete, unlocked, future, past) => {
    if (complete) {
      return (
        <div className="card-footer">
          <a
            href={`/courses/${this.props.challenge_id}/chapters/${this.props.id}`}
            className={classNames('btn btn-block')}
          >
            Completed on{' '}
            {moment(this.props.completed_at).format('MM.DD.YYYY')}
            <i className="fa fa-check ms-2" />
          </a>
        </div>
      );
    }
    if (future) {
      return (
        <div className="card-footer">
          <a
            href={`/courses/${this.props.challenge_id}/chapters/${this.props.id}`}
            className={classNames('btn btn-block disabled')}
          >
            Locked until {moment(this.props.locked_until).format('MM.DD.YYYY')}
            <i className="fa fa-lock ms-2" />
          </a>
        </div>
      );
    }
    if (unlocked) {
      return (
        <div className="card-footer">
          <a
            href={`/courses/${this.props.challenge_id}/chapters/${this.props.id}`}
            className={classNames('btn btn-block')}
          >
            Play
            <i className="fa fa-play ms-2" />
          </a>
        </div>
      );
    }
    if (past) {
      return (
        <div className="card-footer">
          <a
            href={`/courses/${this.props.challenge_id}/chapters/${this.props.id}`}
            className={classNames('btn btn-block disabled')}
          >
            Course Closed
            <i className="fa fa-lock ms-2" />
          </a>
        </div>
      );
    }
    return (
      <div className="card-footer">
        <a
          href={`/courses/${this.props.challenge_id}/chapters/${this.props.id}`}
          className={classNames('btn btn-block disabled')}
        >
          Locked
          <i className="fa fa-lock ms-2" />
        </a>
      </div>
    );
  };

  render() {
    const {
      complete,
      unlocked,
      future,
      past,
    } = this.props;

    return (
      <div
        className={classNames('card card__chapter shadow-sm', {
          'card__chapter--locked': !unlocked || future || past,
          'card__chapter--complete': complete,
        })}
      >
        {this.headerMarkup(complete, unlocked, future, past)}
        <div className="card-body text-start">
          <h4 className="card-title">{this.props.long_name}</h4>
          <div className="description" dangerouslySetInnerHTML={{ __html: this.props.description }} />
        </div>
        {this.footerMarkup(complete, unlocked, future, past)}
      </div>
    );
  }
}

ChapterCard.propTypes = {
  description: PropTypes.string,
  thumbnail: PropTypes.string,
  unlocked: PropTypes.bool,
  complete: PropTypes.bool,
  future: PropTypes.bool,
  past: PropTypes.bool,
  challenge_id: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  long_name: PropTypes.string,
  completed_at: PropTypes.string,
  locked_until: PropTypes.string,
};

ChapterCard.defaultProps = {
  thumbnail: '//placehold.it/400x240?text=Video',
  complete: false,
  unlocked: false,
  future: false,
  past: false,
  description: '',
  name: '',
  long_name: '',
  completed_at: null,
  locked_until: null,
};
