import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChapterCard from './ChapterCard';

export default class ChapterSlider extends Component {
  componentDidMount() {
    $('.chapter-slider')
      .owlCarousel({
        margin: 20,
        nav: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
          1024: {
            items: 3,
          },
        },
      });
  }

  render() {
    return (
      <div className="chapters mt-5">
        <h2>Chapters ({this.props.chapters.length}) </h2>
        <div
          className="chapter-slider owl-carousel mt-4"
        >
          {this.props.chapters.map((chapter => (
            <ChapterCard
              key={chapter.id}
              {...chapter}
            />
          )))}
        </div>
      </div>
    );
  }
}

ChapterSlider.propTypes = {
  chapters: PropTypes.array.isRequired,
};
