import React from 'react';
import { createRoot } from 'react-dom/client';

type ConditionCallback = () => boolean;
type ReactComponent = React.ComponentClass | React.FC;
const defaultCallback: ConditionCallback = () => true;

function renderComponent(
  Component: ReactComponent,
  name: string,
  condition: ConditionCallback = defaultCallback,
): void {
  if (!condition()) return;

  document.querySelectorAll(`[data-react="${name}"]`)
    .forEach((container) => {
      const root = createRoot(container);
      const props = JSON.parse(container.getAttribute('data-props') || '');
      root.render(<Component {...props} />);
    });
}
export default renderComponent;
