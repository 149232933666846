import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import QuizResponse from './QuizResponse';

import './QuizResults.scss';

const IconSwal = withReactContent(Swal);

export default class QuizResults extends Component {
  componentDidMount() {
    if (this.props.showResultsPopUp) {
      this.showResultsPopUp();
    }
  }

  renderAction = () => {
    if (this.props.passing || !this.props.quiz) {
      return (
        <button
          type="button"
          className="btn btn-slant btn-primary"
          onClick={this.props.onNextButtonClick}
        >
          Back to Course
        </button>
      );
    }
    return (
      <div>
        <button
          type="button"
          className="btn btn-slant btn-primary me-2"
          onClick={this.props.onRetry}
        >
          Try Again
        </button>
        <button
          type="button"
          className="btn btn-slant btn-secondary"
          onClick={this.props.onNextButtonClick}
        >
          Back to Course
        </button>
      </div>
    );
  };

  alertType = () => {
    if (this.props.quiz) {
      return this.props.passing ? 'success' : 'error';
    }

    return 'success';
  };

  alertTitle = () => {
    if (this.props.quiz) {
      return this.props.passing ? 'Nice Job!' : 'Bummer.';
    }

    return 'Response Recorded!';
  };

  showResultsPopUp = () => {
    IconSwal.fire({
      icon: this.alertType(),
      title: this.alertTitle(),
    });
  };

  render() {
    return (
      <div>
        <QuizResponse {...this.props} />
        {this.renderAction()}
      </div>
    );
  }
}

QuizResults.propTypes = {
  quiz: PropTypes.bool,
  passing: PropTypes.bool,
  onRetry: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  showResultsPopUp: PropTypes.bool,
};

QuizResults.defaultProps = {
  quiz: true,
  passing: false,
  showResultsPopUp: false,
};
