import React from 'react';
import PropTypes from 'prop-types';

const WistiaVideo = props => (
  <div className="ratio ratio-16x9">
    <div className={`wistia_embed embed-responsive-item wistia_async_${props.video}`} />
  </div>
);

export default WistiaVideo;

WistiaVideo.propTypes = {
  video: PropTypes.string.isRequired,
};
