import React from 'react';
import PropTypes from 'prop-types';

const CourseContent = (props) => {
  const contentBody = content => (
    <div className="flex-grow-1 ms-3">
      <h6 className="my-0">{content.title}</h6>
      <small className="mt-0">{content.short_description}</small>
    </div>
  );

  if (props.contents.length > 0) {
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="course-glance mt-4">
            <div className="row">
              {props.contents.map(content => (
                <div className="col-sm-6 col-md-3 d-flex mb-1" key={content.id}>
                  <div className="flex-shrink-0 me-2">
                    <img src={content.icon.url} alt={content.title} width={75} height={75} className="course-icon" />
                  </div>
                  { content.link_or_file ? (
                    <a href={content.link_or_file} rel="nofollow noreferrer noopener">{contentBody(content)}</a>
                  ) : contentBody(content) }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
};

CourseContent.propTypes = {
  contents: PropTypes.array.isRequired,
};

export default CourseContent;
