import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ChapterVideo from './ChapterVideo';
import CourseFooter from './CourseFooter';

export default class ChapterLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChapter: this.props.current_chapter,
      chapters: this.props.chapters,
    };
  }

  onChapterComplete = () => {
    axios.get(`${this.state.currentChapter.redirect}/chapters`)
      .then((response) => {
        this.setState({
          chapters: response.data,
        });
      });
  };

  render() {
    return (
      <div>
        <ChapterVideo {...this.state.currentChapter} onChapterComplete={this.onChapterComplete} />
        <CourseFooter chapters={this.state.chapters} />
      </div>
    );
  }
}

ChapterLayout.propTypes = {
  current_chapter: PropTypes.object.isRequired,
  chapters: PropTypes.array.isRequired,
};
