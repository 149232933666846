/* eslint-disable import/extensions */

import React from 'react';
import PropTypes from 'prop-types';

import ChapterTimeline from './ChapterTimeline';
import ChapterSlider from './ChapterSlider.jsx';

const CourseFooter = props => (
  <div>
    <hr className="orange-hr" />
    <ChapterTimeline chapters={props.chapters} />
    <ChapterSlider chapters={props.chapters} />
  </div>
);

export default CourseFooter;

CourseFooter.propTypes = {
  chapters: PropTypes.array.isRequired,
};
